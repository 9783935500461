import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';
import './AutoRefill.css';
import Urls from '../../Urls';
import { generateCsaTagSuffix } from "../csa/utils";

export interface PaymentBottomSheetMessaging {
    addPaymentTitle: string;
    addPaymentText: string;
    declineMessage?: string;
}

interface PaymentBottomSheetProps {
    gqlOrderId: string;
    messaging: PaymentBottomSheetMessaging;
    isPillPack?: boolean;
}

const PaymentBottomSheetContent: React.FC<PaymentBottomSheetProps> = ({ 
    gqlOrderId, 
    messaging,
    isPillPack
}) => {
    const {
        addPaymentTitle,
        addPaymentText,
        declineMessage
    } = messaging;

    const iframeUrl = Urls.PAYMENT_PORTAL_URL + gqlOrderId;
    const paymentRedirectUrl = Urls.PAYMENT_REDIRECT_URL + gqlOrderId;
    const iframeHeight = '300px';
    return (
        <>
            {declineMessage && (
                <pui-stack direction="vertical" spacingBottom="medium">
                    <pui-banner id="hba-decline-bottomsheet-error-message" status="error">
                        <pui-text input={declineMessage} textSize="small" textColor="black">
                        </pui-text>
                    </pui-banner>
                </pui-stack>
            )}
            <iframe
                id="card-to-update-payment-method"
                data-csa-c-content-id={`card-to-update-payment-method${generateCsaTagSuffix(isPillPack || false)}`}
                data-csa-c-type="card"
                data-csa-c-slot-id="payment-update-modal"
                sandbox="allow-same-origin allow-scripts allow-forms"
                title="APX View"
                src={iframeUrl}
                style={{ width: "100%", height: iframeHeight, overflow: 'scroll' }}
            />
            <a href={paymentRedirectUrl}>
                <pui-box
                    id="card-to-add-payment-method"
                    data-csa-c-content-id="card-to-add-payment-method"
                    data-csa-c-type="card"
                    data-csa-c-slot-id="payment-update-modal"
                    spacingTop="small"
                    overflow="true"
                    style={{ width: "100%", height: "145px" }}
                >
                    <pui-section
                        paddingTop="20px"
                        paddingBottom="20px"
                        paddingLeft="10px"
                        paddingRight="0px"
                        style={{ height: "145px" }}
                    >
                        <pui-stack direction='vertical'>
                            <pui-stack-item-button overflow="true" buttonPadding="none" style={{ width: "105%" }}>
                                <pui-heading input={addPaymentTitle} textSize="small" />
                                <pui-text textSize="small" spacingTop="small" input={addPaymentText} />
                            </pui-stack-item-button>
                        </pui-stack>
                    </pui-section>
                </pui-box>
            </a>
        </>
    );
};

class PaymentBottomSheet extends Component<PaymentBottomSheetProps, {}> {
    static contextType = I18NContext;

    render() {
        const { gqlOrderId, messaging, isPillPack } = this.props;

        return (
            <>
                <pui-section id="payment-bottom-sheet" class="mobile-only" spacingTop="small" scrollable>
                    <PaymentBottomSheetContent
                        gqlOrderId={gqlOrderId}
                        messaging={messaging}
                        isPillPack={isPillPack}
                    />
                </pui-section>

                <pui-section id="payment-bottom-sheet" class="tablet-and-desktop-only" scrollable>
                    <PaymentBottomSheetContent
                        gqlOrderId={gqlOrderId}
                        messaging={messaging}
                        isPillPack={isPillPack}
                    />
                </pui-section>
            </>
        );
    }
}

export default PaymentBottomSheet;
