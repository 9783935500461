import { ItemGroup, ItemGroupSummary } from "../component";

export const isItemGroupSummaryEmpty = (itemGroupSummary: ItemGroupSummary): boolean => {
    const otherItemGroups = itemGroupSummary.itemGroups?.filter(itemGroup => itemGroup != null);
    const pillPackPacketsItemGroups = itemGroupSummary.pillPackGroupSummary?.itemGroups?.filter(itemGroup => itemGroup != null);
    const pillPackBottleItemGroups = itemGroupSummary.bottleGroupSummary?.itemGroups?.filter(itemGroup => itemGroup != null);
    return areItemGroupsNullOrEmpty(otherItemGroups) && areItemGroupsNullOrEmpty(pillPackPacketsItemGroups) && areItemGroupsNullOrEmpty(pillPackBottleItemGroups);
}

export const areItemGroupsNullOrEmpty = (itemGroups: ItemGroup[]): boolean => {
    return itemGroups == null || itemGroups.length == 0;
}